<!--能人列表-->
<template>
  <div class="bestPerson">
    <div class="breadcrumb w-1400">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/sanxiangxueyuan' }"
          >三农学院</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/school' }"
          >专题讲座</el-breadcrumb-item
        >
        <el-breadcrumb-item>专题详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="p-list w-1400">
      <el-row :gutter="30">
        <el-col :span="17">
          <div class="p-left">
            <div id="videoBox">
              <el-image
                style="width: 100%; height: 100%"
                :src="require('../../../../public/img/sxxy/v-1.png')"
              ></el-image>
            </div>
            <div class="p-videotitle row">
              <div class="pv-name">牦牛养殖技术（1）</div>
              <div>观看人数：865</div>
              <div>课堂分类：家庭农场生产经营</div>
            </div>
            <div class="mt-20">
              <fieldset style="border: 1px solid #ccc">
                <legend
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    border: 1px solid #ccc;
                    padding: 5px 10px;
                  "
                >
                  内容简介
                </legend>
                <div class="ved-text">
                  牦牛是青藏高原特有的畜种，是农牧民群众的主要经济来源。近年来随着农牧业结构不断调整，牦牛养殖产业不断
                  向着集约化规模化方向发展。但在长期牦牛养殖过程中，很多农牧民群众一直坚持传统养殖模式，牦牛生长速度较慢，
                  生产能力较差，品种的生产能力呈现下降趋势，为此就需要我们加强先进高效养殖技术推广应用。本文主要结合实际工
                  作经验，分析了牦牛高效养殖技术要点，希望通过本次研究对提高牦牛养殖水平有一定帮助。
                  <br />
                  牦牛是青藏高原地区普遍选择的牛品种之一，具有适应能力强，适合在高海拔地带生长。牦牛高效养殖是一个十分系统
                  且复杂的工作，对养殖户的养殖经验和养殖方法有较高要求，要想切实提升牦牛的养殖效益，就需要养殖户充分了解牦
                  牛的生长习性和饲养管理问题，这样才能够构建科学合理的饲养管理方案，保证牦牛健康生长。
                </div>
              </fieldset>
            </div>
            <div class="episode">
              <div class="row mt-20">
                <div class="episode-total">共4集</div>
              </div>
              <div class="episode-list mt-20 row">
                <div
                  class="episode-itme"
                  :class="{ act: item == 1 }"
                  v-for="item in 4"
                  :key="item + 'ep'"
                >
                  第{{ item }}集
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="hot-v">
            <div class="hot-v-h3">相关视频</div>
            <div class="hot-v-item" v-for="item in carData" :key="item.id + 'hot'">
              <!-- <el-image style="width:100%;height:100%" :src="require('../../../../public/img/sxxy/v-hot1.png')"></el-image> -->
              <video :src="item.videoUrl" style="width:100%;height:100%"></video>
              <div class="T_video">
                <img
                    width="36px"
                  :src="require('../../../../public/img/video/play.png')"
                  @click="onVideoPlay(`${item.videoUrl}`)"
                  alt=""
                />
              </div>
              <div class="hot-v-title ellipsis">{{item.videoName}}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="height: 200px"></div>

       <!--视频播放窗口-->
    <el-dialog title="" :visible.sync="dialogVideo" width="840px" class="videodlog" append-to-body @close="onVideoClear">
      <i class="el-icon-close icon_btn" @click="dialogVideo = false" style="background:transparent;position:absolute;top:5px;right:10px;font-size:24px;z-index:99999;color:#fff"></i>
      <div class="videoplayer" v-if="dialogVideo">
        <video-player class="bideo-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
      </div>
      <div class="video_List clearfix">
        <ul>
          <li v-for="item in videoList" class="fl" :key="item+'_key'" style="margin-left:15px">
            <div class="video_box">
              <video :src="item" style="width:100%;height:100%"></video>
              <div class="video_shade"><img :src="require('../../../../public/img/video/play.png')" @click="onVideoPlay(item)"></div>
            </div>
            <div class="video_title">{{item.substr(item.lastIndexOf('\\')+1)}}</div>
          </li>
        </ul>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import thirdNav from "@/components/thirdNav";
import { GetVideoInfoPage } from "@/api/xiangningqing";

export default {
  components: {
    thirdNav,
  },
  data() {
    return {
      carData: [],
      dialogVideo: false,
      videoList: [], //多个视频列表
      playerOptions: {},
      videoparams: {
        currentPage: 1,
        pageSize: 5,
        filter: {
          type: "专题讲座",
          infoType: 256581677129797,
        },
      },
    };
  },
  mounted() {
      this.GetVideoList();
  },
  methods: {
    GetVideoList() {
      GetVideoInfoPage(this.videoparams).then((res) => {
        this.carData = res.data.data.list;
      });
    },
    onVideoClear() {
      this.videoList = [];
    },
    onVideoPlay(path) {
      let _src = Array.isArray(path) ? path[0] : path;
      let option = {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: _src, // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      };
      if (Array.isArray(path) && path.length > 1) {
        this.videoList = path;
      }
      this.playerOptions = option;
      this.dialogVideo = true;
    },
  },
};
</script>
<style scoped>
.T_video{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.hot-v-item{
    height: 180px;
    border: 1px solid #ccc;
    position: relative;
}
.hot-v-title {
  line-height: 32px;
  font-size: 18px;
  text-align: center;
  position:absolute;
  left:0;
  bottom:0;
  color:#fff;
  background:rgba(0,0,0,.7);
}
.hot-v-h3 {
  font-size: 20px;
  line-height: 40px;
  font-weight: 600;
}
.hot-v {
  padding: 25px;
  border: 1px solid #777;
}
.pv-name {
  font-size: 20px;
  color: #141414;
  font-weight: 600;
}
.p-videotitle {
  line-height: 40px;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 18px;
  color: #666;
}
.episode-itme:hover,
.episode-itme.act {
  background: #00a0e9;
  color: #fff;
  cursor: pointer;
}
.episode {
  font-size: 18px;
  color: #666;
}
.episode-itme {
  margin-right: 30px;
  border: 1px solid #aaa;
  padding: 5px 10px;
}
.episode-total {
  padding: 5px 10px;
  border: 1px solid #aaa;
}
.ved-text {
  padding: 20px;
  font-size: 16px;
  line-height: 28px;
  color: #666;
  text-indent: 30px;
}
.picbox {
  position: absolute;
  top: -220px;
  left: 0;
  width: 240px;
  height: 220px;
  background: url(../../../../public/img/sxxy/menu2.png) no-repeat center center;
  border: 3px solid #f89807;
}

.bestPerson {
  font-family: "微软雅黑";
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>